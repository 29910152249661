const ROUTES = {
  about: '/about',
  booking: '/booking',
  root: '/',
  privacy: '/policy',
  lifestyle: '/lifestyle',
  favorites: {
    root: '/favorites/*',
    plainRoot: '/favorites/',
    flats: 'flats',
    parking: 'parking',
    keller: 'keller',
  },
  visual: '/flats/visual',
  list: '/flats/list',
  flats: {
    root: '/flats',
    list: '/list',
    visual: {
      root: '/visual',
      house: '/visual/:houseId',
      floor: '/visual/:houseId/:floorId',
      apartment: '/flat/:apartmentId',
    },
  },
  news: '/news',
  onenews: '/news/:slug',
  seafront: '/seafront',
  infrastructure: '/infrastructure',
  houses: '/houses',
  house: '/houses/:id',
  history: '/history',
  location: '/location',
  commercial: '/commercial',
  progress: '/progress',
  oneprogress: '/progress/:slug',
  team: '/team',
  parking: '/parking',
  rareformats: '/rare-formats',
  keller: '/keller',
  spaces: '/spaces',
  landscaping: '/landscaping',
  architecture: '/architecture',
  gallery: '/gallery',
  documents: '/documents',
  contacts: '/contacts',
  improvement: '/improvement',
  error: '/error',
  sitemap: '/sitemap',
  cashback: 'cashback',
  purchaseTerms: {
    root: '/purchase-terms/*',
    plainRoot: '/purchase-terms/',
    fullPayment: 'full',
    installmentPlan: 'installment',
    mortgage: 'mortgage',
    tradeIn: 'trade-in',
    onlinePurchase: 'online',
  },
  cashbackConditions: '/cashback_conditions',
  serverError: '/500',
};

export default ROUTES;
