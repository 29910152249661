const BLOCK_ID = 1420;

export const dbHost = process.env.REACT_APP_DB_HOST;
export const managerFormaApi = process.env.REACT_APP_MANAGER_FORMA;
export const CDN = process.env.REACT_APP_CDN + `/${BLOCK_ID}`;

const constants = {
  url: `${managerFormaApi}/api/v2/flat/?blockId=${BLOCK_ID}`,
  urlForC3: `https://managerforma.keep-calm.ru/api/v2/flat/?blockId=${BLOCK_ID}`,
  urlParking: `${managerFormaApi}/api/v2/parking?blockId=${BLOCK_ID}`,
  urlMain: dbHost,
};

export default constants;
