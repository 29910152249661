export const SITEMAP_FEATURE_ENABLED = process.env.REACT_APP_SITEMAP === 'true';
export const PARKING_FEATURE_ENABLED = process.env.REACT_APP_PARKING === 'true';
export const CAMERA_PROGRES_FEATURE_ENABLED = process.env.REACT_APP_CAMERA_PROGRESSPAGE === 'true';
export const LOGGIA_ERROR_18717 = process.env.REACT_APP_LOGGIA_ERROR_18717 === 'true';
export const LOADER_19286_19285 = process.env.REACT_APP_NEW_LOADER_19286_19285 === 'true';
export const LOADER_19270 = process.env.REACT_APP_NEW_LOADER_19270 === 'true';
export const LOADER_19271 = process.env.REACT_APP_NEW_LOADER_19271 === 'true';
export const LOADER_19274 = process.env.REACT_APP_NEW_LOADER_19274 === 'true';
export const LOADER_19276 = process.env.REACT_APP_NEW_LOADER_19276 === 'true';
export const LOADER_19278 = process.env.REACT_APP_NEW_LOADER_19278 === 'true';
export const LOADER_19281 = process.env.REACT_APP_NEW_LOADER_19281 === 'true';
export const VIEW_ON_WATER_20461 = process.env.REACT_APP_VIEW_ON_WATER_FORMA_20461 === 'true';
export const PROGRESS_SLIDES_20339 = process.env.REACT_APP_PROGRESS_SLIDES_20339 === 'true';
export const CONTACTS_ERROR_PLUG_19289 = process.env.REACT_APP_CONTACTS_ERROR_PLUG_19289 === 'true';
export const LIST_ERROR_21027 = process.env.REACT_APP_LIST_ERROR_21027 === 'true';
export const WHITE_BOX_20774 = process.env.REACT_APP_WHITE_BOX_20774 === 'true';
export const WHITE_BOX_20775 = process.env.REACT_APP_WHITE_BOX_20775 === 'true';
export const LOADER_21127 = process.env.REACT_APP_NEW_LOADER_21127 === 'true';
export const LOADER_PURCHASE_TERMS = process.env.REACT_APP_PURCHASE_TERMS_LOADER_19284 === 'true';
