import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import constants from '../constants/API';

export const fetchAddress = createAsyncThunk('mainSlice/fetchAddress', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(constants.url);
    const designSpace = await response.data?.block?.designSpace;
    if (!designSpace) throw new Error('DesignSpace is not found');

    return designSpace;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const fetchGeneralSettings = createAsyncThunk(
  'mainSlice/fetchGeneralSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/general_settings');
      const data = response.data;
      const flatplanVersion = data?.find(i => i.setting_name === 'image-cache-flatplans')?.settings[0];
      const floorplanVersion = data?.find(i => i.setting_name === 'image-cache-floorplans')?.settings[0];
      const compassVersion = data?.find(i => i.setting_name === 'image-cache-compass')?.settings[0];
      const minigenplanVersion = data?.find(i => i.setting_name === 'image-cache-minigenplan')?.settings[0];
      const minifloorplanVersion = data?.find(i => i.setting_name === 'image-cache-minifloorplans')?.settings[0];

      return {
        generalSettings: data,
        flatplanVersion: flatplanVersion?.value || '',
        floorplanVersion: floorplanVersion?.value || '',
        compassVersion: compassVersion?.value || '',
        minigenplanVersion: minigenplanVersion?.value || '',
        minifloorplanVersion: minifloorplanVersion?.value || '',
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

const mainSlice = createSlice({
  name: 'mainSlice',
  initialState: {
    width: typeof window === 'object' ? window.innerWidth : null,
    height: typeof window === 'object' ? window.innerHeight : null,
    breakpoint: {
      tablet: 1023,
      mobile: 541,
    },
    contacts: {
      coordinates: [55.694165, 37.712803],
      address: {
        text: '',
      },
      phone: {
        text: '+7 (495) 125-40-29',
        link: '+74951254029',
      },
      email: {
        text: 'sales@forma.ru',
        link: 'sales@forma.ru',
      },
      time: {
        text: 'Ежедневно с 9.00 до 21.00',
      },
    },
    isLoadingWebsite: false,
    isANewUser: false,
    generalSettings: [],
    versionsSvg: {
      flatplanVersion: '',
      floorplanVersion: '',
      compassVersion: '',
      minigenplanVersion: '',
      minifloorplanVersion: '',
    },
  },
  reducers: {
    //Перезагрузка сайта при изменении ширины окна (нужно для переинициализации плагинов)
    handleResizeWindow(state, action) {
      const deltaW = state.width - action.payload.width;
      const deltaH = state.height - action.payload.height;

      if (Math.abs(deltaW) > 60 || Math.abs(deltaH) > 250) {
        window.location.reload();

        if (isMobile) {
          window.scrollTo(0, 0);
        }
      }
    },
    changeIsLoadingWebsite(state, action) {
      state.isLoadingWebsite = action.payload;
    },
    setHeight(state, action) {
      if (state.height !== action.payload) {
        state.height = action.payload;
      }
    },
    handleNewUser: (state, action) => {
      state.isANewUser = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.contacts.address.text = action.payload;
    });
    builder.addCase(fetchAddress.rejected, state => {
      state.contacts.address.text = null;
    });

    builder.addCase(fetchGeneralSettings.fulfilled, (state, action) => {
      state.generalSettings = action.payload.generalSettings;
      state.versionsSvg.flatplanVersion = action.payload.flatplanVersion;
      state.versionsSvg.floorplanVersion = action.payload.floorplanVersion;
      state.versionsSvg.compassVersion = action.payload.compassVersion;
      state.versionsSvg.minigenplanVersion = action.payload.minigenplanVersion;
      state.versionsSvg.minifloorplanVersion = action.payload.minifloorplanVersion;
    });
    builder.addCase(fetchGeneralSettings.rejected, state => {
      state.generalSettings = [];
      state.versionsSvg = {
        flatplanVersion: '',
        floorplanVersion: '',
        compassVersion: '',
        minigenplanVersion: '',
        minifloorplanVersion: '',
      };
    });
  },
});

export default mainSlice.reducer;
export const { handleResizeWindow, setHeight, changeIsLoadingWebsite, handleNewUser } = mainSlice.actions;
