import { createSlice } from '@reduxjs/toolkit';
import riverBig from '../image/river_big.jpg';
import river2Min from '../image/river-2.png';
import river2Big from '../image/river-2-big.png';
import river3Min from '../image/river-3.png';
import river3Big from '../image/river-3-big.png';
import riverMin from '../image/river-min.jpg';

const flatSlice = createSlice({
  name: 'flat',
  initialState: {
    sliders: [
      {
        id: 1,
        big: riverBig,
        min: riverMin,
        alt: 'Вид на реку 1',
      },
      {
        id: 2,
        big: river2Big,
        min: river2Min,
        alt: 'Вид на реку 2',
      },
      {
        id: 3,
        big: river3Big,
        min: river3Min,
        alt: 'Вид на реку 3',
      },
    ],
    activeSlide: 1,
    selectedBenefit: null,
    currentData: {
      currentFirstPayment: null,
      currentLoanTerm: null,
      currentMinMonthFee: null,
      currentBenefitPrice: null,
      currentMortgageBenefit: null,
    },
  },
  reducers: {
    setSelectedBenefit(state, action) {
      state.selectedBenefit = action.payload;
    },
    setCurrentData(state, action) {
      const { currentFirstPayment, currentLoanTerm, currentMinMonthFee, currentBenefitPrice, currentMortgageBenefit } =
        action.payload;
      state.currentData.currentFirstPayment = currentFirstPayment;
      state.currentData.currentLoanTerm = currentLoanTerm;
      state.currentData.currentMinMonthFee = currentMinMonthFee;
      state.currentData.currentBenefitPrice = currentBenefitPrice;
      state.currentData.currentMortgageBenefit = currentMortgageBenefit;
    },
    resetSelectedBenefit(state) {
      state.selectedBenefit = null;
      state.currentData = {
        currentFirstPayment: null,
        currentLoanTerm: null,
        currentMinMonthFee: null,
        currentBenefitPrice: null,
        currentMortgageBenefit: null,
      };
    },
    setActiveSlide(state, action) {
      state.activeSlide = action.payload;
    },
    nextSlide(state) {
      if (state.activeSlide < 3) {
        state.activeSlide += 1;
      } else {
        state.activeSlide = 1;
      }
    },
    prevSlide(state) {
      if (state.activeSlide > 1) {
        state.activeSlide -= 1;
      } else {
        state.activeSlide = 3;
      }
    },
  },
});

export const { setSelectedBenefit, setActiveSlide, nextSlide, prevSlide, setCurrentData, resetSelectedBenefit } =
  flatSlice.actions;

export default flatSlice.reducer;
